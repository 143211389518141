import { LogicalFilters } from "src/dashboard/filters/Filter";
import hash from "object-hash";
import { DashboardFilters } from "src/store/dashboards/types";
import { LOGICAL_AND } from "src/shared/types/form";
import cloneDeep from "lodash/cloneDeep";

export const getFiltersHash = (filters: LogicalFilters) =>
  hash({
    logic: filters.logic,
    filters: filters.filters
      .map((block) => ({
        id: block.id,
        enabled: block.enabled,
        logic: block.logic,
        field: block.field,
        basic: block.basic,
        rules: block.rules.filter((rule) => rule.complete),
      }))
      .filter((block) => block.enabled && block.rules.length > 0),
  });

export interface WithFilters {
  filters: LogicalFilters;
}

export const DEFAULT_DASHBOARD_FILTERS: DashboardFilters = {
  logic: LOGICAL_AND,
  filters: [],
  hidden: false,
};

export const cloneDashboardFilters = (
  filters?: DashboardFilters
): DashboardFilters => cloneDeep(filters || DEFAULT_DASHBOARD_FILTERS);
