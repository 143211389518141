import React, { useCallback } from "react";
import { WithDialogProps } from "src/dialogs/WithDialog";
import WithLoadingPopup from "src/dialogs/WithLoadingPopup";
import UsersAuthorizationResource from "src/api/UsersAuthorizationResource";

const WithLogout: React.FC<Pick<WithDialogProps, "children">> = (props) => {
  const handleLogout = useCallback(() => {
    return UsersAuthorizationResource.logout().then(() => {
      window.location.assign("/");
    });
  }, []);

  return <WithLoadingPopup action={handleLogout} children={props.children} />;
};

export default WithLogout;
