import React from "react";
import Cell from "src/shared/ui/Cell";
import NavBar from "src/components/Layout/NavBar";
import FullHeightGrid from "src/shared/ui/FullHeightGrid";
import BorderBox from "src/shared/ui/BorderBox";

interface LayoutProps {
  drawer?: React.ComponentType;
}

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <FullHeightGrid vh columns='1fr' rows='auto 1fr' gap={0}>
      <BorderBox bottom>
        <Cell>
          <NavBar drawer={props.drawer} />
        </Cell>
      </BorderBox>
      <Cell>{props.children}</Cell>
    </FullHeightGrid>
  );
};

export default Layout;
