import React from 'react';
import { Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { BoxProps } from '@material-ui/core/Box';

const SideBarDivider: React.FC<Pick<BoxProps, 'my'>> = (props) => {
  return (
    <Box {...props}>
      <Divider/>
    </Box>
  );
};

SideBarDivider.defaultProps = {
  my: 2,
};

export default SideBarDivider;
