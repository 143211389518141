import React, { ReactElement } from "react";
import WithTextInputDialog from "src/common/WithTextInputDialog";
import useFormPathUpdate from "src/store/form/hooks/useFormPathUpdate";
import { useAppSelector } from "src/store";
import { selectFormState } from "src/store/form/form";
import { PlainFn } from "src/shared/types";

interface WithFormRenameDialogProps {
  children: (onClick: PlainFn, name: string) => ReactElement;
}

const WithFormRenameDialog: React.FC<WithFormRenameDialogProps> = ({
  children,
}) => {
  const onNameChange = useFormPathUpdate("name");
  const name = useAppSelector((state) => selectFormState(state).name);

  return (
    <WithTextInputDialog
      initialValue={name}
      title='Rename this form'
      okText='Save'
      placeholder='Name your form'
      onCapture={onNameChange}
    >
      {(onOpen) => children(onOpen, name)}
    </WithTextInputDialog>
  );
};

export default WithFormRenameDialog;
