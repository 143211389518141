import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { FETCH_STATUS_IDLE, FetchStatus } from "src/store/resource";
import { DashboardCollaborator } from "src/store/collaborators/types";
import { sortCompareAccessLevel, sortCompareDate, StringMap } from "src/global";
import { AppState } from "src/store";
import CollaboratorsResource from "src/api/CollaboratorsResource";
import createAsyncThunkReducers from "src/store/createAsyncThunkReducers";
import { shouldFetch } from "src/store/utils";

export const fetchResourceCollaborators = createAsyncThunk(
  "collaborators/fetchForResource",
  (resourceId: string) => CollaboratorsResource.list({ resourceId }),
  {
    condition(resourceId: string, { getState }) {
      const state = getState() as AppState;
      return shouldFetch(
        selectCollaboratorsStatusByResource(state, resourceId)
      );
    },
  }
);

const adapter = createEntityAdapter<DashboardCollaborator>({
  sortComparer(a, b) {
    // Sort by access level descending by default
    const accessCompare = sortCompareAccessLevel(b.accessLevel, a.accessLevel);
    if (accessCompare !== 0) return accessCompare;

    // Break ties by date created descending
    return sortCompareDate(b.createdAt, a.createdAt);
  },
});

interface State extends EntityState<DashboardCollaborator> {
  status: StringMap<FetchStatus>;
}

const initialState: State = adapter.getInitialState({
  status: {},
});

const slice = createSlice({
  name: "collaborators",
  initialState,
  reducers: {
    save: adapter.upsertOne,
    remove: adapter.removeOne,
  },

  extraReducers: (builder) => {
    createAsyncThunkReducers<State, DashboardCollaborator[], string>({
      thunk: fetchResourceCollaborators,
      receive: adapter.upsertMany,
      setStatus: (state, status, resourceId) =>
        (state.status[resourceId] = status),
    })(builder);
  },
});
export const CollaboratorReducer = slice.reducer;

// actions
export const saveCollaborator = slice.actions.save;
export const removeCollaborator = slice.actions.remove;

// selectors
const selectState = (state: AppState) => state.collaborators;
const selectors = adapter.getSelectors(selectState);

export const selectCollaboratorById = selectors.selectById;

export const selectCollaboratorIdsByResource = createSelector(
  [
    selectors.selectIds,
    selectors.selectEntities,
    (state: AppState, resourceId: string) => resourceId,
  ],
  (ids, entities, resourceId) => {
    return ids.filter((id) => {
      const entity = entities[id];
      return entity && entity.resource === resourceId;
    });
  }
);

export const selectCollaboratorsStatusByResource = (
  state: AppState,
  resourceId: string
) => selectState(state).status[resourceId] || FETCH_STATUS_IDLE;
