import { createTheme } from "@material-ui/core/styles";

let MuiTheme = createTheme({
  spacing: 4,
  typography: {
    fontSize: 14,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;',
    body1: {
      fontSize: "unset",
      lineHeight: "unset",
    },
    body2: {
      lineHeight: "unset",
    },
    h2: {
      fontSize: "1.7rem",
      fontWeight: 500,
      letterSpacing: "-0.01em",
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "1.3rem",
      fontWeight: 500,
      letterSpacing: "-0.008em",
      lineHeight: 1.2,
    },
    h6: {
      fontSize: "1.1rem",
      fontWeight: 500,
      letterSpacing: "-0.008em",
      lineHeight: 1.2,
    },
  },
  palette: {
    text: {
      primary: "#172B4D",
    },
  },
});

Object.assign(MuiTheme.breakpoints.values, { sm: 768 });

MuiTheme = {
  ...MuiTheme,
  overrides: {
    MuiInputBase: {
      input: {
        "&:-webkit-autofill": {
          transitionDelay: "9999s",
          transitionProperty: "background-color, color",
        },
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: MuiTheme.spacing(2),
        paddingBottom: MuiTheme.spacing(2),
      },
    },
    MuiButton: {
      label: {
        textTransform: "initial",
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
      tooltipPlacementBottom: {
        [MuiTheme.breakpoints.up("xs")]: {
          marginTop: MuiTheme.spacing(1),
        },
      },
      tooltipPlacementTop: {
        [MuiTheme.breakpoints.up("xs")]: {
          marginBottom: MuiTheme.spacing(1),
        },
      },
      tooltipPlacementRight: {
        [MuiTheme.breakpoints.up("xs")]: {
          marginLeft: MuiTheme.spacing(1),
        },
      },
      tooltipPlacementLeft: {
        [MuiTheme.breakpoints.up("xs")]: {
          marginRight: MuiTheme.spacing(1),
        },
      },
    },
    MuiDialog: {
      paper: {
        [MuiTheme.breakpoints.only("xs")]: {
          margin: 10,
        },
      },
      paperScrollPaper: {
        [MuiTheme.breakpoints.only("xs")]: {
          maxHeight: "calc(100% - 20px)",
        },
      },
      paperFullWidth: {
        [MuiTheme.breakpoints.only("xs")]: {
          width: "calc(100% - 20px)",
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: MuiTheme.spacing(1),
        paddingBottom: MuiTheme.spacing(1),
        minHeight: 0,
        fontSize: MuiTheme.typography.fontSize,
        color: "rgb(62, 62, 64)",
      },
      gutters: {
        paddingLeft: MuiTheme.spacing(3),
        paddingRight: MuiTheme.spacing(3),
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 46,
      },
    },
    MuiAvatar: {
      root: {
        width: 30,
        height: 30,
      },
    },

    MuiSvgIcon: {
      root: {
        fontSize: 21,
      },
    },

    MuiSwitch: {
      switchBase: {
        height: "auto",
      },
    },
    MuiTab: {
      root: {
        minHeight: 0,
        marginRight: 16,

        letterSpacing: "0.1em",
        lineHeight: "16px",
        fontWeight: 600,

        [MuiTheme.breakpoints.up("xs")]: {
          fontSize: 10,
          paddingRight: 0,
          paddingLeft: 0,
          minWidth: 0,
        },
      },
    },
  },
};

export default MuiTheme;
