import Api from "src/shared/Api";
import { Communications, Person } from "src/shared/types/user";

const BASE = "/users/me";
type WithPassword = { password: string };

export default class UsersResource {
  static get() {
    return Api.get<Person>(BASE);
  }

  static updateName(params: Pick<Person, "name">) {
    return Api.patch<Pick<Person, "name">>(Api.join(BASE, "name"), params);
  }

  static updatePassword(params: WithPassword & { newPassword: string }) {
    return Api.patch(Api.join(BASE, "password"), params);
  }

  static updateEmail(params: WithPassword & { email: string }) {
    return Api.patch<Pick<Person, "email" | "gravatar">>(
      Api.join(BASE, "email"),
      params
    );
  }

  static updateCommunications(params: Communications) {
    return Api.patch<Pick<Person, "communications">>(
      Api.join(BASE, "communications"),
      { communications: params }
    );
  }
}
