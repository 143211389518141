import React, { useState } from "react";
import LoadingButton from "src/common/LoadingButton";
import EnhancedTextField from "src/common/EnhancedTextField";
import WithDialog, { WithDialogProps } from "src/dialogs/WithDialog";
import { VoidOrPromise } from "src/utils";

interface WithTextInputDialogProps
  extends Pick<WithDialogProps, "title" | "children" | "maxWidth"> {
  initialValue?: string;
  okText: string;
  placeholder: string;
  onCapture: (value: string) => VoidOrPromise;
}

const WithTextInputDialog: React.FC<WithTextInputDialogProps> = (props) => {
  const {
    initialValue = "",
    okText,
    placeholder,
    onCapture,
    children,
    title,
    maxWidth = "sm",
  } = props;

  const [value, setValue] = useState(initialValue);

  return (
    <WithDialog
      fullWidth
      maxWidth={maxWidth}
      title={title}
      children={children}
      onConfirm={(onClose) => Promise.resolve(onCapture(value)).then(onClose)}
      content={
        <EnhancedTextField
          autoFocus
          fullWidth
          variant="outlined"
          placeholder={placeholder}
          value={value}
          onChange={setValue}
        />
      }
      confirm={(onClick, loading) => (
        <LoadingButton
          loading={loading}
          onClick={onClick}
          disabled={!value}
          color="primary"
          variant="outlined"
        >
          {okText}
        </LoadingButton>
      )}
    />
  );
};

export default WithTextInputDialog;
