import React from "react";
import UserAvatar from "src/common/UserAvatar";
import { AppState } from "src/store";
import { connect } from "react-redux";

const mapStateToProps = (state: AppState) => ({
  avatar: state.me!.gravatar,
  name: state.me!.name,
});

const LoggedUserAvatar = connect(mapStateToProps)(React.memo(UserAvatar));
export default LoggedUserAvatar;
