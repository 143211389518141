import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SideBarItem, {
  BaseSideBarItemProps,
} from "src/components/SideBar/SideBarItem";

const useStyles = makeStyles((theme: Theme) => ({
  active: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.dark,
  },
}));

type SideBarLinkProps = BaseSideBarItemProps &
  Pick<NavLinkProps, "to" | "exact">;

const SideBarLink: React.FC<SideBarLinkProps> = (props) => {
  const classes = useStyles();

  return (
    <SideBarItem
      {...props}
      component={NavLink}
      activeClassName={classes.active}
    />
  );
};

export default React.memo(SideBarLink);
