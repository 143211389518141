import Api from "src/shared/Api";
import { omit } from "src/global/objectUtils";
import { CollaboratorAccessLevel, WithID } from "src/global/";
import { DashboardCollaborator } from "src/store/collaborators/types";

type Dash = { resourceId: string };
const BASE = "resources/:resourceId/collaborators";

export default class CollaboratorsResource {
  static list(params: Dash) {
    return Api.get<DashboardCollaborator[]>(Api.replace(BASE, params));
  }

  static create(params: Dash & { email: string }) {
    return Api.post<DashboardCollaborator>(
      Api.replace(BASE, params),
      omit(params, "resourceId")
    );
  }

  static update(
    params: WithID & Dash & { accessLevel: CollaboratorAccessLevel }
  ) {
    return Api.patch<DashboardCollaborator>(
      Api.replace([BASE, ":id"], params),
      omit(params, ["id", "resourceId"])
    );
  }

  static delete(params: WithID & Dash) {
    return Api.delete(Api.replace([BASE, ":id"], params));
  }
}
