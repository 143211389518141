import React, { memo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "src/store";
import AddIcon from "@material-ui/icons/Add";
import WithTextInputDialog from "src/common/WithTextInputDialog";
import { SideBarContainer } from "src/components/SideBar";
import SideBarButton from "src/components/SideBar/SideBarButton";
import SideBarDivider from "src/components/SideBar/SideBarDivider";
import Spacer from "src/shared/ui/Spacer";
import { AUTO_ALL, AUTO_SHARED, AUTO_UNCATEGORIZED } from "src/workspace/utils";
import WorkspaceSideBarLink from "src/workspace/Layout/WorkspaceSideBarLink";
import WorkspacesResource from "src/api/WorkspacesResource";
import { saveWorkspace, selectWorkspaceIds } from "src/store/workspaces";
import usePushLocation from "src/hooks/usePushLocation";

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const pushLocation = usePushLocation();

  const handleCreate = useCallback(
    (name: string) =>
      WorkspacesResource.create({ name }).then((workspace) => {
        dispatch(saveWorkspace(workspace));
        pushLocation(`/workspaces/${workspace.id}`);
      }),
    [dispatch, pushLocation]
  );

  const workspaces = useAppSelector(selectWorkspaceIds);

  return (
    <>
      <SideBarContainer>
        <WorkspaceSideBarLink id={AUTO_ALL} />
        <WorkspaceSideBarLink id={AUTO_SHARED} />
        <SideBarDivider />
        <WorkspaceSideBarLink id={AUTO_UNCATEGORIZED} />
        {workspaces.map((ws) => (
          <WorkspaceSideBarLink key={ws} id={ws as any} />
        ))}

        <Spacer size={5} vertical />

        <WithTextInputDialog
          title='Create a new workspace'
          okText='Create workspace'
          placeholder='Name your workspace'
          onCapture={handleCreate}
        >
          {(onOpen) => (
            <SideBarButton
              color='primary'
              onClick={onOpen}
              Icon={AddIcon}
              text='New Workspace'
            />
          )}
        </WithTextInputDialog>
      </SideBarContainer>
    </>
  );
};

export default memo(Sidebar);
