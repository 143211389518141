import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  nanoid,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";
import { Field } from "src/shared/types/form";
import { Draft } from "immer";
import { reconcileField } from "src/shared/fieldMetas";
import { getAdjacentValue } from "src/store/utils";
import reorderArrayItems from "src/DnD/reorderArrayItems";
import { isAnswerable, TYPE_STATEMENT } from "src/global";
import { indexToLetter } from "src/shared/helpers";
import { formCleanup } from "src/store/form/bootstrap";
import { AppState } from "src/store";

interface State extends EntityState<Field> {
  activeId: string;
  settingPanel: boolean;
}

const adapter = createEntityAdapter<Field>();

function arrayMove(arr: any[], oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}

function saveOne(state: Draft<State>, field: Field, afterId?: string) {
  adapter.addOne(state, field);
  state.activeId = field.id;
  if (afterId) {
    const index = state.ids.indexOf(afterId) + 1;
    const currentIndex = state.ids.indexOf(field.id);
    if (index > 0 && index !== currentIndex)
      arrayMove(state.ids, currentIndex, index);
  }
}

const activateNeighbourReducer =
  (step: number) => (state: Draft<State> | any) => {
    const { ids, activeId } = state;
    const currentIndex = ids.indexOf(activeId);
    const index = currentIndex + step;
    if (index >= 0 && index < ids.length) state.activeId = ids[index];
  };

const settingPanelReducer = (shown: boolean) => (state: Draft<State>) => {
  state.settingPanel = shown;
};

const getInitialState = (): State =>
  adapter.getInitialState({
    activeId: "",
    settingPanel: false,
  });

const slice = createSlice({
  name: "fields",
  initialState: getInitialState(),
  reducers: {
    receive(state: any, action: PayloadAction<Field[]>) {
      adapter.setAll(state, action);
      if (state.ids.length > 0) state.activeId = state.ids[0];
    },

    create(state, action: PayloadAction<{ type: string; afterId?: string }>) {
      const { type, afterId } = action.payload;

      const field: Field = {
        type,
        id: nanoid(),
        title: "",
        validations: [],
        jumps: [],
      };

      saveOne(state, reconcileField(field), afterId);
    },

    remove(state: any, action: PayloadAction<string>) {
      const id = action.payload;

      // Activate adjacent field
      if (state.activeId === id)
        state.activeId = getAdjacentValue(state.ids, id) || "";

      // Remove
      adapter.removeOne(state, id);
    },

    duplicate(state, action: PayloadAction<string>) {
      const id = action.payload;
      const field = state.entities[id];

      if (!field) return;

      const newField = reconcileField({
        ...field,
        id: nanoid(),
        reference: undefined,
      });

      saveOne(state, newField, id);
    },

    patch(state, action: PayloadAction<Update<Field>>) {
      const { changes, id } = action.payload;
      const field = state.entities[id];
      if (field) {
        if (changes.hasOwnProperty("type") && field.type !== changes.type)
          adapter.addOne(state, reconcileField({ ...field, ...changes }));
        else adapter.updateOne(state, action);
      }
    },

    swap(state, action: PayloadAction<{ src: number; dst: number }>) {
      const { src, dst } = action.payload;
      state.ids = reorderArrayItems(state.ids, src, dst);
    },

    next: activateNeighbourReducer(1),
    prev: activateNeighbourReducer(-1),
    activate(state, action: PayloadAction<string>) {
      state.activeId = action.payload;
    },

    showSettingPanel: settingPanelReducer(true),
    hideSettingPanel: settingPanelReducer(false),
  },
  extraReducers: (builder) => {
    builder.addCase(formCleanup, getInitialState);
  },
});

export const FieldReducer = slice.reducer;

// actions
export const showFieldSettingPanel = slice.actions.showSettingPanel;
export const hideFieldSettingPanel = slice.actions.hideSettingPanel;
export const activateNextField = slice.actions.next;
export const activatePrevField = slice.actions.prev;
export const activateFieldById = slice.actions.activate;
export const moveField = slice.actions.swap;
export const patchFieldById = slice.actions.patch;
export const duplicateFieldById = slice.actions.duplicate;
export const removeFieldById = slice.actions.remove;
export const createField = slice.actions.create;
export const fieldsReceived = slice.actions.receive;

// selectors
export const selectFieldsState = (state: AppState) => state.form.fields;
const selectors = adapter.getSelectors(selectFieldsState);
export const selectFieldById = selectors.selectById;
export const selectFieldIds = selectors.selectIds;
export const selectFieldList = selectors.selectAll;
export const selectFieldEntities = selectors.selectEntities;
export const selectFieldCount = selectors.selectTotal;

export const fieldPropSelector =
  <K extends keyof Field>(id: string, key: K) =>
  (state: AppState): Field[K] =>
    selectors.selectById(state, id)![key];

export const fieldPositionByIdSelector = (id: string) => (state: AppState) => {
  const fields = selectors.selectAll(state);
  const { type } = selectors.selectById(state, id)!;

  const getIndexOf = (filterFn: (type: string) => boolean) =>
    fields
      .filter((field) => filterFn(field.type))
      .findIndex((field) => field.id === id);

  if (isAnswerable(type)) return getIndexOf(isAnswerable) + 1;

  if (type === TYPE_STATEMENT)
    return indexToLetter(getIndexOf((type) => type === TYPE_STATEMENT));

  return undefined;
};

export const selectAnswerableFieldList = createSelector(
  selectFieldList,
  (fields) => fields.filter(isAnswerable)
);
