import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { Box, Button, Theme } from "@material-ui/core";
import BorderBox from "src/shared/ui/BorderBox";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";

interface SidePanelButtonProps {
  icon: React.ReactNode;
  label: string;
  onClick: React.MouseEventHandler;
  description?: string;
  active?: boolean;
}

const useButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.grey[800],
    borderRadius: 0,
    "&.active": {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.primary.main,
    },
  },
}));

const SidePanelButton: React.FC<SidePanelButtonProps> = (props) => {
  const classes = useButtonStyles();
  const { icon, label, description, onClick, active } = props;

  return (
    <BorderBox bottom>
      <Box>
        <Tooltip placement='right' interactive title={description || label}>
          <Button
            variant='text'
            fullWidth
            onClick={onClick}
            className={clsx({ active, [classes.root]: true })}
          >
            <Box>
              {React.cloneElement(icon as React.ReactElement, {
                style: { fontSize: 21 },
              })}
              <Box
                fontSize={12}
                fontWeight={500}
                marginTop={-2}
                display='block'
                component='span'
              >
                {label}
              </Box>
            </Box>
          </Button>
        </Tooltip>
      </Box>
    </BorderBox>
  );
};

export default React.memo(SidePanelButton);
