import Api from "src/shared/Api";
import { omit } from "src/global/objectUtils";
import { Form, WorkspaceForm } from "src/shared/types/form";
import { WithID } from "src/global/";

const BASE = "/forms";

const makePatch =
  <Key extends keyof Form>(key: Key) =>
  (params: Pick<Form, "id" | Key>) =>
    Api.patch(Api.replace([BASE, ":id", key], params), omit(params, "id"));

export default class FormsResource {
  static list = () => Api.get<WorkspaceForm[]>(BASE);

  static get = (params: WithID) =>
    Api.get<Form>(Api.replace([BASE, ":id"], params));

  static delete = (params: WithID) =>
    Api.delete(Api.replace([BASE, ":id"], params));

  static clone = (params: WithID & Partial<Pick<Form, "workspace">>) =>
    Api.post<WorkspaceForm>(
      Api.replace([BASE, ":id"], params),
      omit(params, ["id"])
    );

  static create = (params: Pick<Form, "name" | "workspace">) =>
    Api.post<WorkspaceForm>(BASE, params);

  static save = (params: Form) =>
    Api.put<void>(Api.replace([BASE, ":id"], params), omit(params, ["id"]));

  static patchName = makePatch("name");
  static patchCustomID = makePatch("customID");
  static patchWorkspace = makePatch("workspace");
}
