import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FETCH_STATUS_IDLE, FetchStatus } from "src/store/resource";
import { StringMap } from "src/global";
import { AppState } from "src/store";
import createAsyncThunkReducers from "src/store/createAsyncThunkReducers";
import FormFieldsResource from "src/api/FormFieldsResource";
import { shouldFetch } from "src/store/utils";
import { FieldArray } from "src/global/fields";

export const fetchFormFields = createAsyncThunk(
  "forms/fields/fetch",
  (formId: string) => FormFieldsResource.list({ formId }),
  {
    condition(formId, { getState }) {
      const state = getState() as AppState;
      const { status } = selectFormFieldsById(state, formId);
      return shouldFetch(status);
    },
  }
);

interface FormFields {
  status: FetchStatus;
  fields: FieldArray;
}

type State = StringMap<FormFields>;
const initialState: State = {};

const slice = createSlice({
  name: "dashboards/form-fields",
  initialState,
  reducers: {
    clearAll: () => ({}),
  },
  extraReducers: (builder) => {
    createAsyncThunkReducers<State, FieldArray, string>({
      thunk: fetchFormFields,
      receive: (state, payload, formId) => (state[formId].fields = payload),
      setStatus: (state, status, formId) => {
        if (!state[formId])
          state[formId] = {
            status: FETCH_STATUS_IDLE,
            fields: [],
          };
        state[formId].status = status;
      },
    })(builder);
  },
});

export const FormFieldsReducer = slice.reducer;

const DEFAULT = {
  status: FETCH_STATUS_IDLE,
  fields: [],
};

export const selectFormFieldsById = (state: AppState, formId: string) =>
  state.formFields[formId] || DEFAULT;
