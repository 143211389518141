import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { FETCH_STATUS_IDLE, ResourceState } from "src/store/resource";
import { createSortCompareDate } from "src/global";
import { Workspace } from "src/shared/types/workspace";
import { AppState } from "src/store";
import WorkspacesResource from "src/api/WorkspacesResource";
import createAsyncThunkReducers from "src/store/createAsyncThunkReducers";
import { shouldFetch } from "src/store/utils";

export const fetchWorkspaces = createAsyncThunk(
  "workspaces/fetch",
  () => WorkspacesResource.list(),
  {
    condition(arg: void, { getState }) {
      const state = getState() as AppState;
      return shouldFetch(selectWorkspacesStatus(state));
    },
  }
);

type State = ResourceState<Workspace>;

const adapter = createEntityAdapter<Workspace>({
  sortComparer: createSortCompareDate<Workspace>((x) => x.createdAt),
});

const initialState: State = adapter.getInitialState({
  status: FETCH_STATUS_IDLE,
});

const slice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    save: adapter.addOne,
    remove: adapter.removeOne,
    patch: adapter.updateOne,
  },
  extraReducers: (builder) => {
    createAsyncThunkReducers<State, Workspace[]>({
      thunk: fetchWorkspaces,
      receive: adapter.setAll,
      setStatus: (state, status) => (state.status = status),
    })(builder);
  },
});

export const WorkspaceReducer = slice.reducer;

export const patchWorkspaceById = slice.actions.patch;
export const removeWorkspaceById = slice.actions.remove;
export const saveWorkspace = slice.actions.save;

const selectState = (state: AppState) => state.workspaces;
const selectors = adapter.getSelectors(selectState);
export const selectWorkspaceById = selectors.selectById;
export const selectWorkspaceIds = selectors.selectIds;
export const selectWorkspaceList = selectors.selectAll;
export const selectWorkspaceEntities = selectors.selectEntities;
export const selectWorkspacesStatus = (state: AppState) =>
  selectState(state).status;
