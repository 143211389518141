import React from "react";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/MenuOutlined";
import { PlainRouterLink } from "src/components/Links";
import { APP_NAME } from "src/global";
import { makeStyles } from "@material-ui/core";
import useDrawer from "src/useDrawer";
import Drawer from "@material-ui/core/Drawer";

interface LogoOrMenuProps {
  drawer?: React.ComponentType;
}

const useStyles = makeStyles({
  logoImage: {
    height: 24,
    width: "auto",
    marginBottom: -5,
  },
});

const LogoOrMenu: React.FC<LogoOrMenuProps> = (props) => {
  const classes = useStyles();
  const [isDrawerOpen, onDrawerOpen, onDrawerClose] = useDrawer();
  const DrawerComponent = props.drawer;

  return (
    <>
      <Hidden smUp>
        <IconButton edge='end' onClick={onDrawerOpen}>
          <MenuIcon />
        </IconButton>
        {DrawerComponent && (
          <Drawer
            open={isDrawerOpen}
            onClick={onDrawerClose}
            onClose={onDrawerClose}
            onKeyDown={onDrawerClose}
          >
            <DrawerComponent />
          </Drawer>
        )}
      </Hidden>
      <Hidden xsDown>
        <PlainRouterLink to='/'>
          <img
            src='/logo-simple.png'
            alt={APP_NAME}
            className={classes.logoImage}
          />
        </PlainRouterLink>
      </Hidden>
    </>
  );
};

export default React.memo(LogoOrMenu);
