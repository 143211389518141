import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { FormsReducer } from "src/store/forms";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { WorkspaceReducer } from "src/store/workspaces";
import { DashboardWidgetReducer } from "src/store/widgets";
import { CollaboratorReducer } from "src/store/collaborators";
import { DashboardReducer } from "src/store/dashboards";
import { FormFieldsReducer } from "src/store/fields";
import FormReducer from "src/store/form";
import { MeReducer } from "src/store/me";

const rootReducer = combineReducers({
  collaborators: CollaboratorReducer,
  workspaces: WorkspaceReducer,

  forms: FormsReducer,
  formFields: FormFieldsReducer,
  form: FormReducer,

  me: MeReducer,

  dashboards: DashboardReducer,
  widgets: DashboardWidgetReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;

export type AppState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
