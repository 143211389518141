import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Draft } from "immer";
import { getAdjacentValue } from "src/store/utils";
import {
  FETCH_STATUS_IDLE,
  FETCH_STATUS_SUCCESS,
  ResourceState,
} from "src/store/resource";
import { FormResponse, ResponsesData } from "src/shared/types/response";
import FormResponsesResource, {
  FetchParams,
} from "src/api/FormResponsesResource";
import createAsyncThunkReducers from "src/store/createAsyncThunkReducers";
import { selectFieldIds, selectFieldList } from "src/store/form/fields";
import { isAnswerable } from "src/global";
import { DatePair, sortParameter } from "src/utils";
import { formCleanup } from "src/store/form/bootstrap";
import { AppState } from "src/store";
import { DEFAULT_DASHBOARD_FILTERS } from "src/dashboard/filters/utils";
import { DashboardFilters } from "src/store/dashboards/types";
import { SetStateAction } from "react";
import { applyAction } from "src/shared/actions";

export const fetchResponses = createAsyncThunk(
  "responses/fetch",
  (params: FetchParams) => FormResponsesResource.list(params)
);

export const checkResponseSortField = createAsyncThunk(
  "responses/check-sort-field",
  async (arg, { getState }) => {
    const state = getState() as AppState;
    const { sortField } = state.form.responses;

    const fieldIds = selectFieldIds(state);
    if (fieldIds.includes(sortField)) return sortField;

    const firstAnswerableField = selectFieldList(state).find(isAnswerable);
    if (firstAnswerableField) return firstAnswerableField.id;

    return "";
  }
);

const selectDownloadParams = (state: AppState): FetchParams => {
  const { sort, checked, filters } = state.form.responses;

  const params: FetchParams = {
    formId: state.form.form.id,
    sort: sortParameter("createdAt", sort.createdAt),
  };

  // Filter params
  if (checked && checked.length) params.ids = checked;
  else params.filters = filters;

  return params;
};

export const downloadResponses = createAsyncThunk(
  "responses/download",
  async (arg, { getState }) => {
    const params = selectDownloadParams(getState() as AppState);
    const res = await FormResponsesResource.download(params);
    return res.url;
  }
);

interface State extends ResourceState<FormResponse> {
  activeId: string;
  totalCount: number;
  sort: {
    createdAt?: boolean;
  };
  filter: DatePair;
  filters: DashboardFilters;
  checked: string[];
  sortField: string;
}

const adapter = createEntityAdapter<FormResponse>();

const getInitialState = (): State =>
  adapter.getInitialState({
    status: FETCH_STATUS_IDLE,
    activeId: "",
    totalCount: 0,
    checked: [],
    sortField: "",
    sort: {},
    filter: {},
    filters: DEFAULT_DASHBOARD_FILTERS,
  });

const activateNeighbourReducer =
  (step: number) => (state: Draft<State> | any) => {
    const { ids, activeId } = state;
    const currentIndex = ids.indexOf(activeId);
    const index = currentIndex + step;
    if (index >= 0 && index < ids.length) state.activeId = ids[index];
  };

const pruneChecked = (state: Draft<State>) => {
  if (state.checked.length > 0) {
    const ids = new Set(state.ids);
    const pruned = state.checked.filter((id) => ids.has(id));
    if (pruned.length !== state.checked.length) state.checked = pruned;
  }
};

const checkActiveId = (state: Draft<State> | any) => {
  const { activeId, ids } = state;
  if (ids.length === 0) state.activeId = "";
  else if (!activeId || !ids.includes(activeId)) state.activeId = ids[0];
};

const slice = createSlice({
  name: "responses",
  initialState: getInitialState(),
  reducers: {
    save: adapter.upsertOne,

    remove(state: any, action: PayloadAction<string>) {
      const id = action.payload;

      // Activate adjacent field
      if (state.activeId === id)
        state.activeId = getAdjacentValue(state.ids, id) || "";

      // Remove
      adapter.removeOne(state, id);
      state.totalCount -= 1;
    },

    removeMany(state, action: PayloadAction<string[]>) {
      const ids = action.payload;
      adapter.removeMany(state, ids);
      state.totalCount -= ids.length;
      pruneChecked(state);
      checkActiveId(state);
    },

    toggleSortDirection(state) {
      state.sort.createdAt = !state.sort.createdAt;
    },

    setSortField(state, action: PayloadAction<string>) {
      state.sortField = action.payload;
    },

    setFilter(state, action: PayloadAction<DatePair>) {
      state.filter = action.payload;
    },

    setFilters(state, action: PayloadAction<SetStateAction<DashboardFilters>>) {
      state.filters = applyAction(action.payload, state.filters);
    },

    next: activateNeighbourReducer(1),
    prev: activateNeighbourReducer(-1),
    activate(state, action: PayloadAction<string>) {
      state.activeId = action.payload;
    },

    toggleChecked(state, action: PayloadAction<string>) {
      const id = action.payload;
      if (state.checked.includes(id))
        state.checked = state.checked.filter((i) => i !== id);
      else state.checked.push(id);
    },

    toggleCheckedAll(state: any) {
      state.checked = state.checked.length > 0 ? [] : [...state.ids];
    },
  },

  extraReducers: (builder) => {
    createAsyncThunkReducers<State, ResponsesData, FetchParams>({
      thunk: fetchResponses,
      receive: (state, payload) => {
        const { responses, count } = payload;
        adapter.setAll(state, responses);
        state.status = FETCH_STATUS_SUCCESS;
        state.totalCount = count;
        pruneChecked(state);
        checkActiveId(state);
      },
      setStatus: (state, status) => (state.status = status),
    })(builder);

    builder.addCase(checkResponseSortField.fulfilled, (state, action) => {
      state.sortField = action.payload;
    });

    builder.addCase(formCleanup, getInitialState);
  },
});

export const ResponseReducer = slice.reducer;

// actions
export const activateNextResponse = slice.actions.next;
export const activatePrevResponse = slice.actions.prev;
export const activateResponseById = slice.actions.activate;
export const toggleResponseCheckById = slice.actions.toggleChecked;
export const toggleResponseCheckAll = slice.actions.toggleCheckedAll;
export const saveResponse = slice.actions.save;
export const removeResponseById = slice.actions.remove;
export const removeResponseByIds = slice.actions.removeMany;
export const toggleResponseSortDirection = slice.actions.toggleSortDirection;
export const responseSortFieldChanged = slice.actions.setSortField;
export const responseFilterChanged = slice.actions.setFilter;
export const responseFiltersChanged = slice.actions.setFilters;

// selectors
export const selectResponsesState = (state: AppState) => state.form.responses;
const selectors = adapter.getSelectors(selectResponsesState);

export const selectResponseById = selectors.selectById;
export const selectResponseIds = selectors.selectIds;
export const selectResponseList = selectors.selectAll;
export const selectResponseEntities = selectors.selectEntities;
export const selectResponseCount = selectors.selectTotal;
export const selectResponsesStatus = (state: AppState) =>
  selectResponsesState(state).status;

export const responsePropSelector =
  <K extends keyof FormResponse>(id: string, key: K) =>
  (state: AppState): FormResponse[K] =>
    selectors.selectById(state, id)![key];
