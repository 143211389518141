import { EntityState } from '@reduxjs/toolkit';

export const FETCH_STATUS_LOADING = 'loading';
export const FETCH_STATUS_SUCCESS = 'success';
export const FETCH_STATUS_ERROR = 'error';
export const FETCH_STATUS_COMPLETE = 'complete';
export const FETCH_STATUS_IDLE = '';

export type FetchStatus =
  typeof FETCH_STATUS_IDLE |
  typeof FETCH_STATUS_LOADING |
  typeof FETCH_STATUS_SUCCESS |
  typeof FETCH_STATUS_ERROR |
  typeof FETCH_STATUS_COMPLETE;

export interface ResourceState<T> extends EntityState<T> {
  readonly status: FetchStatus
}
