import { StringMap } from "src/global";
import moment from "moment";

export interface Communications extends StringMap {
  educational?: boolean;
  company?: boolean;
  offers?: boolean;
}

export interface Subscription {
  nextBillingDate: string;
  status: string;
  statusUpdatedAt: string;
  planID: string;
}

export const isProPlanActive = (s?: Subscription): boolean =>
  !!s && moment(s.nextBillingDate) >= moment().startOf("day");

export interface Person {
  id: string;
  email: string;
  gravatar: string;
  name: string;
  createdAt: string;
  communications?: Communications;

  subscription?: Subscription;
  totalCreditedUnitCount: number;
  usedUnitCount: number;
  remainingUnitCount: number;
}
