import { combineReducers } from "@reduxjs/toolkit";
import { FieldReducer } from "src/store/form/fields";
import { FormDataReducer } from "src/store/form/form";
import { ResultReducer } from "src/store/form/results";
import { ResponseReducer } from "src/store/form/responses";

const FormReducer = combineReducers({
  form: FormDataReducer,
  fields: FieldReducer,
  results: ResultReducer,
  responses: ResponseReducer,
});

export default FormReducer;
