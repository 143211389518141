import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LightForm } from "src/shared/types/form";
import { Draft } from "immer";
import { formCleanup } from "src/store/form/bootstrap";
import { AppState } from "src/store";

// @ts-ignore
const getInitialState = (): LightForm => ({});

interface SlicePatchPayload<Key extends keyof LightForm> {
  key: Key;
  changes: Partial<LightForm[Key]>;
}

const slice = createSlice({
  name: "form",
  initialState: getInitialState(),
  reducers: {
    receive(state, action: PayloadAction<LightForm>) {
      return action.payload;
    },
    patch(state, action: PayloadAction<Partial<LightForm>>) {
      return Object.assign(state, action.payload);
    },
    patchSlice<Key extends keyof LightForm>(
      state: Draft<LightForm>,
      action: PayloadAction<SlicePatchPayload<Key>>
    ) {
      const { key, changes } = action.payload;
      Object.assign(state[key], changes);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(formCleanup, getInitialState);
  },
});

export const FormDataReducer = slice.reducer;

export const selectFormState = (state: AppState) => state.form.form;

export const selectFormId = (state: AppState) => state.form.form.id;

// actions
export const formReceived = slice.actions.receive;
export const patchForm = slice.actions.patch;
export const patchFormSlice = slice.actions.patchSlice;
