import React from "react";
import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: (size: any) => ({
    width: size,
    height: size,
    display: "inline-block",
  }),
});

export interface UserAvatarProps {
  avatar: string;
  name: string;
  size?: string | number;
  onClick?: React.MouseEventHandler;
}

const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const classes = useStyles(props.size!);
  return (
    <Avatar
      src={props.avatar}
      alt={props.name}
      onClick={props.onClick}
      classes={classes}
    />
  );
};

UserAvatar.defaultProps = {
  size: 30,
};

export default React.memo(UserAvatar);
