import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Person } from "src/shared/types/user";

type State = Person | null;

const slice = createSlice({
  name: "me",
  initialState: null as State,
  reducers: {
    save: (state, action: PayloadAction<Person>) => action.payload,
    patch(state, action: PayloadAction<Partial<Person>>) {
      Object.assign(state, action.payload);
    },
  },
});

export const MeReducer = slice.reducer;
export const saveMe = slice.actions.save;
export const patchMe = slice.actions.patch;
