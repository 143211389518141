import React from "react";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { PlainRouterLink } from "src/components/Links";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  tabs: {
    minHeight: 0,
    marginBottom: -2,
    display: "inline-block",
  },
  tab: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const MainHeader: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const tab = location.pathname.startsWith("/dashboards") ? 1 : 0;

  return (
    <div className={classes.root}>
      <Tabs value={tab} indicatorColor="primary" className={classes.tabs}>
        <Tab
          component={PlainRouterLink}
          to="/workspaces"
          label="Forms"
          className={classes.tab}
        />
        <Tab
          component={PlainRouterLink}
          to="/dashboards"
          label="Analytics"
          className={classes.tab}
        />
      </Tabs>
    </div>
  );
};

export default React.memo(MainHeader);
