import React from "react";
import Grid from "src/shared/ui/Grid";
import Cell from "src/shared/ui/Cell";
import { Box, Typography } from "@material-ui/core";

interface SideBarHeaderProps {
  text: string;
  action?: React.ReactNode;
}

const SideBarHeader: React.FC<SideBarHeaderProps> = (props) => {
  return (
    <Box ml={5} mr={3} mt={5} mb={5}>
      <Grid columns='1fr auto'>
        <Cell middle>
          <Typography variant='h6' noWrap>
            {props.text}
          </Typography>
        </Cell>
        <Cell middle>{props.action}</Cell>
      </Grid>
    </Box>
  );
};

export default React.memo(SideBarHeader);
