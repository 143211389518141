import React, { memo } from "react";
import {
  SideBarContainer,
  SideBarHeader,
  SideBarLink,
} from "src/components/SideBar";
import SideBarButton from "src/components/SideBar/SideBarButton";
import SideBarDivider from "src/components/SideBar/SideBarDivider";
import WithLogout from "src/dialogs/WithLogout";

const AccountSideBar: React.FC = () => {
  const path = (x: string) => `/account/${x}`;

  return (
    <SideBarContainer>
      <SideBarHeader text="Account" />
      <SideBarLink text="Preferences" to={path("preferences")} />
      <SideBarLink text="Communications" to={path("communications")} />
      <SideBarLink text="Plan + Billing" to={path("billing")} />
      <SideBarLink text="My offers" to={path("offers")} />
      <SideBarLink text="API Access" to={path("api-access")} />
      <SideBarDivider />
      <WithLogout>
        {(onOpen) => (
          <SideBarButton text="Logout" onClick={onOpen} size="medium" />
        )}
      </WithLogout>
    </SideBarContainer>
  );
};

export default memo(AccountSideBar);
