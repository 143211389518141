import React from "react";
import { Box, List } from "@material-ui/core";
import Scrollable from "src/shared/ui/Scrollable";
import useTheme from "@material-ui/core/styles/useTheme";

const SideBarContainer: React.FC = (props) => {
  const theme = useTheme();

  return (
    <Box height='100%' bgcolor={theme.palette.background.default}>
      <Scrollable>
        <List>{props.children}</List>
      </Scrollable>
    </Box>
  );
};

export default SideBarContainer;
