import Api from "src/shared/Api";

const BASE = "users";
type Token = { token: string };
type Password = { password: string };
type Email = { email: string };

export default class UsersAuthorizationResource {
  static logout() {
    return Api.get(Api.join(BASE, "logout"));
  }

  static login(params: Email & Password) {
    return Api.post<void>(Api.join(BASE, "login"), params);
  }

  static register(params: object) {
    return Api.post<void>(Api.join(BASE, "register"), params);
  }

  static sendPasswordResetLink(params: Email) {
    return Api.post(Api.join(BASE, "password-reset-link"), params);
  }

  static getPasswordResetEmail(params: Token) {
    return Api.get<string>(Api.join(BASE, "password-reset"), params);
  }

  static resetPassword(params: Token & Password) {
    return Api.post<void>(Api.join(BASE, "password-reset"), params);
  }
}
