import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import Grid from "src/shared/ui/Grid";
import Cell from "src/shared/ui/Cell";
import Hidden from "@material-ui/core/Hidden";
import LoggedUserAvatarMenu from "src/components/Layout/LoggedUserAvatarMenu";
import BillingInfo from "src/components/Layout/BillingInfo";
import LogoOrMenu from "src/components/Layout/LogoOrMenu";
import MainHeader from "src/components/Layout/MainHeader";

interface NavBarProps {
  drawer?: React.ComponentType;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 3),
  },
  verticalSpacing: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const NavBar: React.FC<NavBarProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid columns='1fr auto 1fr'>
        <Cell middle className={classes.verticalSpacing}>
          <LogoOrMenu drawer={props.drawer} />
        </Cell>
        <Cell middle>
          <MainHeader />
        </Cell>
        <Cell middle className={classes.verticalSpacing}>
          <Grid columns='1fr auto' xsColumns='1fr' gap={0}>
            <Hidden xsDown>
              <Cell middle>
                <BillingInfo />
              </Cell>
            </Hidden>
            <Cell middle>
              <LoggedUserAvatarMenu />
            </Cell>
          </Grid>
        </Cell>
      </Grid>
    </div>
  );
};

export default React.memo(NavBar);
