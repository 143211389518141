import React, { cloneElement, useMemo } from 'react';

interface BorderBoxProps {
  children: React.ReactElement
  size?: number
  top?: boolean
  bottom?: boolean
  left?: boolean
  right?: boolean
  vertical?: boolean
  horizontal?: boolean
  all?: boolean
  light?: boolean
}

const BorderBox: React.FC<BorderBoxProps> = props => {
  const {
    children,
    size,
    top,
    bottom,
    left,
    right,
    vertical,
    horizontal,
    all,
    light,
  } = props;

  const color = light ? 'rgba(256, 256, 256, .7)' : 'rgba(0, 0, 0, .07)';
  const border = `${size}px solid ${color}`;
  const { style } = children.props;

  const styleAttribute = useMemo(() => ({
    style: {
      ...style,
      ...((top || horizontal || all) && { borderTop: border }),
      ...((bottom || horizontal || all) && { borderBottom: border }),
      ...((left || vertical || all) && { borderLeft: border }),
      ...((right || vertical || all) && { borderRight: border }),
    },
  }), [style, all, horizontal, vertical, top, right, bottom, left, border]);

  return cloneElement(children, styleAttribute);
};

BorderBox.defaultProps = {
  size: 1,
};

export default BorderBox;
