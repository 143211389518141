import React from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
}

const LoadingButton: React.FC<LoadingButtonProps> = ({ loading, children, disabled, ...others }) => {
  return (
    <Button
      {...others}
      disabled={loading || disabled}>
      {loading && <Box mr={2} clone><CircularProgress size={15}/></Box>}
      {children}
    </Button>
  );
};

export default LoadingButton;
