import moment from "moment";
import React from "react";
import { PlainFn } from "src/shared/types";

export const sortParameter = (field: string, ascending?: boolean) =>
  `${ascending ? "+" : "-"}${field}`;

export interface DatePair {
  from?: Date;
  to?: Date;
}

export const datePairToQueryParameters = (filter: DatePair) => {
  let { to, from } = filter;
  if (!to) to = from;
  if (!from) from = to;

  return {
    from: from ? moment(from).startOf("day").toDate() : undefined,
    to: to ? moment(to).endOf("day").toDate() : undefined,
  };
};

export type VoidOrPromise = Promise<void> | void;

export const loginSuccess = () => {
  window.location.assign("/login");
};

export const withStopPropagation =
  (onClick: PlainFn) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick();
  };
