import { useCallback, useMemo, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { PlainFn } from "src/shared/types";

export default function useDialog(
  open?: boolean
): [boolean, PlainFn, PlainFn, string] {
  const [isOpen, setDialogOpen] = useState(open || false);
  const [uniqueKey, setUniqueKey] = useState(nanoid);

  const doOpen = useCallback(() => {
    setUniqueKey(nanoid());
    setDialogOpen(true);
  }, []);

  const doClose = useCallback(() => setDialogOpen(false), []);

  return useMemo(
    () => [isOpen, doOpen, doClose, uniqueKey],
    [isOpen, doOpen, doClose, uniqueKey]
  );
}
