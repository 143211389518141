import Api from "src/shared/Api";
import { omit } from "src/global/objectUtils";
import { Dashboard } from "src/store/dashboards/types";
import { PartialWithKey } from "src/dashboard/api/utils";
import { WithID } from "src/global/";

const BASE = "/dashboards";

export default class DashboardsResource {
  static list() {
    return Api.get<Dashboard[]>(BASE);
  }

  static clone(
    params: WithID & Partial<Pick<Dashboard, "name" | "description">>
  ) {
    return Api.post<Dashboard>(
      Api.replace([BASE, ":id"], params),
      omit(params, ["id"])
    );
  }

  static create(params: Pick<Dashboard, "name" | "description">) {
    return Api.post<Dashboard>(BASE, params);
  }

  static update(params: PartialWithKey<Dashboard, "id">) {
    return Api.patch<Dashboard>(
      Api.replace([BASE, ":id"], params),
      omit(params, ["id"])
    );
  }

  static get(params: Pick<Dashboard, "id">) {
    return Api.get<Dashboard>(Api.replace([BASE, ":id"], params));
  }

  static delete(params: Pick<Dashboard, "id">) {
    return Api.delete(Api.replace([BASE, ":id"], params));
  }
}
