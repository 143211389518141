export default class Db {
  static set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  static get(key: string, defaultValue?: string) {
    return localStorage.getItem(key) || defaultValue;
  }

  static clear(key: string) {
    localStorage.removeItem(key);
  }
}
