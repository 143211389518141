import {
  FETCH_STATUS_ERROR,
  FETCH_STATUS_IDLE,
  FETCH_STATUS_LOADING,
  FetchStatus,
} from "src/store/resource";

export const getAdjacentValue = (stack: string[], needle: string) => {
  const index = stack.indexOf(needle);
  const adjacentIndex = index + 1 < stack.length ? index + 1 : index - 1;
  return adjacentIndex > -1 ? stack[adjacentIndex] : undefined;
};

export const shouldFetch = (status: FetchStatus) =>
  status === FETCH_STATUS_ERROR || status === FETCH_STATUS_IDLE;

export const isLoading = (status: FetchStatus) =>
  status === FETCH_STATUS_LOADING || status === FETCH_STATUS_IDLE;
