import React from "react";
import { AppState } from "src/store";
import { connect } from "react-redux";
import { Redirect, RedirectProps, Route, RouteProps } from "react-router";

interface ConditionalRouteOptions {
  bad: boolean;
  to: (props: RouteProps) => RedirectProps["to"];
}

type ProtectedRouteProps = RouteProps & {
  loggedIn?: boolean;
  component: React.ComponentType<any>;
};

export default function ConditionalRoute({ bad, to }: ConditionalRouteOptions) {
  const WrappedRoute: React.FC<ProtectedRouteProps> = (props) => {
    const { loggedIn, component: Component, ...rest } = props;

    return (
      <Route
        {...rest}
        render={(cProps) =>
          loggedIn === bad ? (
            <Redirect to={to(rest)} />
          ) : (
            <Component {...cProps} />
          )
        }
      />
    );
  };

  const mapState = (state: AppState) => ({
    loggedIn: !!state.me,
  });

  return connect(mapState)(WrappedRoute);
}
