import React, { ReactElement, Fragment, useCallback } from "react";
import PopupLoading from "src/shared/ui/PopupLoading";
import useDialog from "src/common/useDialog";
import useNotification from "src/shared/hooks/useNotification";
import { PlainFn } from "src/shared/types";

interface WithLoadingPopupProps {
  children: (onOpen: PlainFn) => ReactElement;
  action: () => Promise<any>;
}

const WithLoadingPopup: React.FC<WithLoadingPopupProps> = (props) => {
  const { children, action } = props;
  const [isModalOpen, onModalOpen, onModalClose] = useDialog();
  const { onShowError } = useNotification();

  const handleClick = useCallback(() => {
    onModalOpen();
    action()
      .catch((e) => onShowError(e.message, true))
      .finally(onModalClose);
  }, [onModalOpen, onModalClose, action, onShowError]);

  return (
    <Fragment>
      {children(handleClick)}
      <PopupLoading open={isModalOpen} />
    </Fragment>
  );
};

export default WithLoadingPopup;
