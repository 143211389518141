import React, { ChangeEventHandler, memo, useCallback, useEffect, useState } from 'react';
import { BaseTextFieldProps } from '@material-ui/core/TextField/TextField';

export default function enhanceTextField<P extends BaseTextFieldProps>(
  TextFieldComponent: React.ComponentType<P>,
) {
  type EnhancedTextFieldProps =
    Omit<P, 'onChange' | 'onBlur' | 'defaultValue' | 'error'> & {
    onChange: (value: string) => void
    lazy?: boolean
    validate?: (value: string) => boolean
  }

  const EnhancedTextField: React.FC<EnhancedTextFieldProps> = props => {
    const { onChange, lazy, validate, value: defaultValue, ...otherProps } = props;

    const [error, setError] = useState(false);
    const [value, setValue] = useState(defaultValue);

    const handleValueChange = useCallback(
      (value: string, emit?: boolean) => {
        setValue(value);
        const isValid = !validate || validate(value);
        setError(!isValid);
        emit && isValid && onChange(value);
      }, [onChange, validate]);

    useEffect(
      () => handleValueChange(defaultValue as string, false),
      [defaultValue, handleValueChange]);

    const handleEvent = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, emit?: boolean) =>
        handleValueChange(e.target.value, emit),
      [handleValueChange]);

    const handleChange: ChangeEventHandler<HTMLInputElement> =
      useCallback(e => handleEvent(e, !lazy), [handleEvent, lazy]);

    const handleBlur: ChangeEventHandler<HTMLInputElement> =
      useCallback(e => handleEvent(e, lazy), [handleEvent, lazy]);

    return (
      <TextFieldComponent
        {...otherProps as unknown as P}
        error={error}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}/>
    );
  };

  return memo(EnhancedTextField);
}
