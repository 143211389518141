import React, { useEffect, useState } from "react";
import { useAppDispatch } from "src/store";
import LoadingSpinner from "src/shared/ui/LoadingSpinner";
import UsersResource from "src/api/UsersResource";
import {
  FETCH_STATUS_COMPLETE,
  FETCH_STATUS_IDLE,
  FETCH_STATUS_LOADING,
  FetchStatus,
} from "src/store/resource";
import { shouldFetch } from "src/store/utils";
import { saveMe } from "src/store/me";

const LoggedProvider: React.FC = (props) => {
  const [status, setStatus] = useState<FetchStatus>(FETCH_STATUS_IDLE);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shouldFetch(status)) {
      setStatus(FETCH_STATUS_LOADING);
      UsersResource.get()
        .then((data) => {
          dispatch(saveMe(data));
        })
        .catch(console.log)
        .finally(() => setStatus(FETCH_STATUS_COMPLETE));
    }
  }, [status, dispatch]);

  if (status !== FETCH_STATUS_COMPLETE) return <LoadingSpinner vh />;

  return <>{props.children}</>;
};

export default LoggedProvider;
