import {
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { Widget } from "src/dashboard/analytics/widget";
import { AppState } from "src/store";

type State = EntityState<Widget>;

const adapter = createEntityAdapter<Widget>({
  selectId: (widget) => widget.config.id,
});

const initialState: State = adapter.getInitialState();

const slice = createSlice({
  name: "widgets",
  initialState,
  reducers: {
    save: adapter.upsertOne,
    remove: adapter.removeOne,
  },
});

export const DashboardWidgetReducer = slice.reducer;

// actions
export const removeWidgetById = slice.actions.remove;
export const saveWidget = slice.actions.save;

// selectors
const selectState = (state: AppState) => state.widgets;
const selectors = adapter.getSelectors(selectState);
export const selectWidgetById = selectors.selectById;
export const selectWidgetList = selectors.selectAll;

export const selectFirstWidgetByResourceId = (
  state: AppState,
  resourceId: string
) =>
  selectWidgetList(state).find(
    (widget) => widget.config.attachedTo === resourceId
  );
