import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  FETCH_STATUS_IDLE,
  FetchStatus,
  ResourceState,
} from "src/store/resource";
import createAsyncThunkReducers from "src/store/createAsyncThunkReducers";
import { SummaryResult } from "src/shared/types/response";
import FormResponsesResource from "src/api/FormResponsesResource";
import { shouldFetch } from "src/store/utils";
import { formCleanup } from "src/store/form/bootstrap";
import { AppState } from "src/store";

export const fetchResults = createAsyncThunk(
  "results/fetch",
  (arg, { getState }) => {
    const state = getState() as AppState;
    return FormResponsesResource.summary({ formId: state.form.form.id });
  },
  {
    condition(arg: void, { getState }) {
      const state = getState() as AppState;
      return shouldFetch(selectResultStatus(state));
    },
  }
);

type State = ResourceState<SummaryResult>;
const adapter = createEntityAdapter<SummaryResult>();

const getInitialState = (): State =>
  adapter.getInitialState({
    status: FETCH_STATUS_IDLE,
  });

const slice = createSlice({
  name: "results",
  initialState: getInitialState(),
  reducers: {
    receive: adapter.setAll,
    setStatus(state, action: PayloadAction<FetchStatus>) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    createAsyncThunkReducers<State, SummaryResult[]>({
      thunk: fetchResults,
      receive: (state, payload) => adapter.addMany(state, payload),
      setStatus: (state, status) => (state.status = status),
    })(builder);

    builder.addCase(formCleanup, getInitialState);
  },
});

export const ResultReducer = slice.reducer;

// selectors
const selectState = (state: AppState) => state.form.results;
const selectors = adapter.getSelectors(selectState);
export const selectResultById = selectors.selectById;
export const selectResultIds = selectors.selectIds;
export const selectResultList = selectors.selectAll;
export const selectResultEntities = selectors.selectEntities;
export const selectResultCount = selectors.selectTotal;
export const selectResultStatus = (state: AppState) =>
  selectState(state).status;
