import Api from "src/shared/Api";
import { omit } from "src/global/objectUtils";
import { Workspace } from "src/shared/types/workspace";
import { PartialWithKey } from "src/dashboard/api/utils";
import { WithID } from "src/global/";

const BASE = "/workspaces";

export default class WorkspacesResource {
  static list() {
    return Api.get<Workspace[]>(BASE);
  }

  static get(params: WithID) {
    return Api.get<Workspace>(Api.replace([BASE, ":id"], params));
  }

  static delete(params: WithID) {
    return Api.delete(Api.replace([BASE, ":id"], params));
  }

  static patch(params: PartialWithKey<Workspace, "id">) {
    return Api.patch<Workspace>(
      Api.replace([BASE, ":id"], params),
      omit(params, ["id"])
    );
  }

  static create(params: Pick<Workspace, "name">) {
    return Api.post<Workspace>(BASE, params);
  }
}
