import Api from "src/shared/Api";
import {
  FormResponse,
  FormResponseData,
  ResponsesData,
  SummaryResult,
} from "src/shared/types/response";
import { omit } from "src/global/objectUtils";
import { WithFormId } from "src/api/utils";
import { DashboardFilters } from "src/store/dashboards/types";

export interface FetchParams extends WithFormId {
  ids?: string[];
  sort?: string;
  filters?: DashboardFilters;
}

type UpdateParams = Pick<FormResponseData, "answers" | "jumpPath"> &
  WithFormId & {
    responseId: string;
  };

type DeleteParams = WithFormId & {
  ids: Array<string>;
};

const BASE = "/forms/:formId/responses";

export default class FormResponsesResources {
  static download = (params: FetchParams) =>
    Api.post<{ url: string }>(
      Api.replace([BASE, "download"], params),
      omit(params, "formId")
    );

  static list = (params: FetchParams) =>
    Api.post<ResponsesData>(
      Api.replace([BASE, "list"], params),
      omit(params, "formId")
    );

  static summary = (params: WithFormId) =>
    Api.get<SummaryResult[]>(Api.replace([BASE, "summary"], params));

  static deleteMany = (params: DeleteParams) =>
    Api.delete(Api.replace(BASE, params), omit(params, "formId"));

  static update = (params: UpdateParams) =>
    Api.patch<FormResponse>(
      Api.replace([BASE, ":responseId"], params),
      omit(params, ["formId", "responseId"])
    );
}
