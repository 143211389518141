import React, { memo, useCallback, useState } from "react";
import LoggedUserAvatar from "src/common/LoggedUserAvatar";
import { Divider, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import WithLogout from "src/dialogs/WithLogout";
import { withStopPropagation } from "src/utils";

const LoggedUserAvatarMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);

  const handleAvatarClick = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    []
  );

  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <LoggedUserAvatar onClick={handleAvatarClick} />
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem component={Link} to={"/account/preferences"}>
          <ListItemText>Preferences</ListItemText>
        </MenuItem>
        <MenuItem component={Link} to={"/account/communications"}>
          <ListItemText>Communications</ListItemText>
        </MenuItem>
        <MenuItem component={Link} to={"/account/billing"}>
          <ListItemText>Plan + Billing</ListItemText>
        </MenuItem>
        <MenuItem component={Link} to={"/account/offers"}>
          <ListItemText>My offers</ListItemText>
        </MenuItem>
        <MenuItem component={Link} to={"/account/api-access"}>
          <ListItemText>API Keys</ListItemText>
        </MenuItem>
        <Divider />
        <WithLogout>
          {(onOpen) => (
            <MenuItem onClick={withStopPropagation(onOpen)}>
              <ListItemText primary='Logout' />
            </MenuItem>
          )}
        </WithLogout>
      </Menu>
    </>
  );
};

export default memo(LoggedUserAvatarMenu);
