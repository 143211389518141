import React from 'react';
import { ListItem, ListItemSecondaryAction, ListItemText, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(0, 2),
    cursor: 'default',
  },
  root: {
    borderRadius: theme.spacing(1),
  },
}));

const useIconStyles = makeStyles({
  root: {
    color: 'unset',
    minWidth: 26,
  },
});

type GetComponentProps<T> = T extends React.ComponentType<infer P> | React.Component<infer P> ? P : never

export interface BaseSideBarItemProps {
  text: string
  action?: string | number
  Icon?: React.ComponentType<SvgIconProps>
}

type SideBarItemProps<C=React.ComponentType<any>> =
  GetComponentProps<C> & BaseSideBarItemProps & {component: C};

const SideBarItem: React.FC<SideBarItemProps> = ({text, action, Icon, ...others}) => {
  const classes = useStyles();
  const iconClasses = useIconStyles();

  return (
    <ListItem
      {...others}
      button
      dense
      classes={classes} >
      {Icon && (
        <ListItemIcon classes={iconClasses}>
          <Icon fontSize="small"/>
        </ListItemIcon>
      )}
      <ListItemText>
        <Typography noWrap>{text}</Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <Typography variant="body2">{action}</Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default React.memo(SideBarItem);
