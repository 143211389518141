import React from "react";
import Store from "src/store";
import Loadable from "react-loadable";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import ProtectedRoute from "src/components/Routing/ProtectedRoute";
import UnprotectedRoute from "src/components/Routing/UnprotectedRoute";
import BaseApp from "src/shared/BaseApp";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import LoadingSpinner from "src/shared/ui/LoadingSpinner";
import ProtectedWithLayoutRoute from "./ProtectedWithLayoutRoute";
import AccountSideBar from "src/pages/AccountPage/AccountSideBar";
import LoggedProvider from "src/components/Routing/LoggedProvider";
import WorkspaceSidebar from "src/workspace/Layout/Sidebar";
import SidePanel from "src/pages/FormPage/withLayout/SidePanel/SidePanel";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Offline, Online, Detector } from "react-detect-offline";
import { store } from "react-notifications-component";
import { Wifi, WifiOff } from "@material-ui/icons";

function Notification(message: string) {
  return (
    <div
      style={{
        display: "flex",
        padding: "11px 6px",
        width: "100%",
        height: 62,
        backgroundColor: "#0f2f26",
        color: "white",
        borderRadius: 5,
      }}
    >
      <Wifi />
      <div style={{ margin: "0 0 0 5px", color: "#FAE042" }}>
        <h4 style={{ margin: "5px 0", color: "rgb(183, 162, 35)" }}>
          Network Status
        </h4>
        <p style={{ margin: 0, color: "#FAE042" }}>{message}</p>
      </div>
    </div>
  );
}

const customNotification = (
  val: "success" | "info" | "danger",
  message: string
) => {
  store.addNotification({
    content: Notification(message),
    type: val,
    container: "bottom-left",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      pauseOnHover: true,
    },
  });
};

const PageLoadingSpinner: React.FC = () => <LoadingSpinner />;

const Login = Loadable({
  loader: () => import("src/components/Auth/Login"),
  loading: PageLoadingSpinner,
});

const Register = Loadable({
  loader: () => import("src/components/Auth/Register"),
  loading: PageLoadingSpinner,
});

const PasswordReset = Loadable({
  loader: () => import("src/components/Auth/PasswordReset"),
  loading: PageLoadingSpinner,
});

const CollaboratorInvitationPage = Loadable({
  loader: () => import("src/pages/CollaboratorInvitationPage"),
  loading: PageLoadingSpinner,
});

const FormPage = Loadable({
  loader: () => import("src/pages/FormPage"),
  loading: PageLoadingSpinner,
});

const WorkspacePage = Loadable({
  loader: () => import("src/pages/WorkspacePage"),
  loading: PageLoadingSpinner,
});

const AccountPage = Loadable({
  loader: () => import("src/pages/AccountPage"),
  loading: PageLoadingSpinner,
});

const DashboardViewPage = Loadable({
  loader: () => import("src/pages/DashboardsPage/DashboardViewPage"),
  loading: PageLoadingSpinner,
});

const DashboardListPage = Loadable({
  loader: () => import("src/pages/DashboardsPage/DashboardListPage"),
  loading: PageLoadingSpinner,
});

const App: React.FC = () => {
  return (
    <>
      <Detector
        render={({ online }) => (
          <div style={{ display: "none" }}>
            You are currently {online ? "online" : "offline"}
          </div>
        )}
        onChange={(value) => {
          if (value === true)
            customNotification("success", "Network connection established");
          else
            customNotification("info", "Seems there is no network connection");
        }}
      />
      <Offline>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <WifiOff style={{ fontSize: "100px" }} />
          <h3>No network connection detected</h3>
        </div>
      </Offline>
      <Online>
        <BaseApp store={Store}>
          <DndProvider backend={HTML5Backend}>
            <LoggedProvider>
              <Router>
                <Switch>
                  <UnprotectedRoute path='/login' component={Login} />
                  <UnprotectedRoute path='/register' component={Register} />
                  <UnprotectedRoute
                    path='/password-reset/:token?'
                    component={PasswordReset}
                  />

                  <ProtectedRoute
                    path='/invitations/:token'
                    component={CollaboratorInvitationPage}
                  />

                  <ProtectedWithLayoutRoute
                    path='/account'
                    component={AccountPage}
                    drawer={AccountSideBar}
                  />

                  <ProtectedWithLayoutRoute
                    path='/forms/:id/:section?'
                    component={FormPage}
                    drawer={SidePanel}
                  />

                  <ProtectedWithLayoutRoute
                    path='/workspaces/:id?'
                    component={WorkspacePage}
                    drawer={WorkspaceSidebar}
                  />

                  <ProtectedWithLayoutRoute
                    path='/dashboards/:dashboardId'
                    component={DashboardViewPage}
                  />

                  <ProtectedWithLayoutRoute
                    path='/dashboards'
                    component={DashboardListPage}
                  />

                  <Redirect to='/workspaces' />
                </Switch>
              </Router>
            </LoggedProvider>
          </DndProvider>
        </BaseApp>
      </Online>
    </>
  );
};

export default App;
