import { ActionReducerMapBuilder, AsyncThunk } from "@reduxjs/toolkit";
import { Draft } from "immer";
import {
  FETCH_STATUS_ERROR,
  FETCH_STATUS_LOADING,
  FETCH_STATUS_SUCCESS,
  FetchStatus,
} from "src/store/resource";
import { NoInfer } from "tslint/lib/language/rule/abstractRule";

const createAsyncThunkReducers =
  <State, Returned, ThunkArg = void, X = {}>({
    thunk,
    receive,
    setStatus,
  }: {
    thunk: AsyncThunk<Returned, ThunkArg, X>;
    receive: (state: Draft<State>, payload: Returned, arg: ThunkArg) => void;
    setStatus: (
      state: Draft<State>,
      status: FetchStatus,
      arg: ThunkArg
    ) => void;
  }) =>
  (builder: ActionReducerMapBuilder<NoInfer<State>>) => {
    const s = (state: any) => state as unknown as Draft<State>;

    builder.addCase(thunk.fulfilled, (state, action: any) => {
      receive(s(state), action.payload, action.meta.arg);
      setStatus(s(state), FETCH_STATUS_SUCCESS, action.meta.arg);
    });

    builder.addCase(thunk.rejected, (state, action: any) => {
      setStatus(s(state), FETCH_STATUS_ERROR, action.meta.arg);
    });

    builder.addCase(thunk.pending, (state, action: any) => {
      setStatus(s(state), FETCH_STATUS_LOADING, action.meta.arg);
    });
  };

export default createAsyncThunkReducers;
