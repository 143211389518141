import { ElementType } from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';

const MakePlainLink =
  <C extends ElementType>(Component: C) =>
    styled(Component)({
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    });

export const PlainMuiLink = MakePlainLink(MuiLink);
export const PlainRouterLink = MakePlainLink(RouterLink);
