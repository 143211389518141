import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import useDialog from "src/common/useDialog";
import { VoidOrPromise } from "src/utils";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Spacer from "src/shared/ui/Spacer";
import useNotification from "src/shared/hooks/useNotification";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import defaultCancel from "src/dialogs/defaultCancel";
import { PlainFn } from "src/shared/types";

type WorkCallback = (onClose: PlainFn) => VoidOrPromise;

export interface WithDialogProps extends Omit<DialogProps, "open" | "title"> {
  title: ReactElement | string;
  children: (onOpen: PlainFn) => ReactElement;
  content: ReactElement | ((onClose: PlainFn) => ReactElement);
  cancel?: (onClick: PlainFn, loading: boolean) => ReactElement;
  confirm?: (onClick: PlainFn, loading: boolean) => ReactElement;
  onConfirm?: WorkCallback;
  onOpen?: WorkCallback;
}

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    padding: theme.spacing(0, 6, 5),
  },
}));

const WithDialog: React.FC<WithDialogProps> = (props) => {
  const classes = useStyles();
  const [isModalOpen, onModalOpen, onModalClose] = useDialog();
  const [loading, setLoading] = useState(false);
  const { onShowError } = useNotification();
  const {
    title,
    children,
    onConfirm,
    onOpen,
    content,
    cancel = defaultCancel,
    confirm,
    ...dialogProps
  } = props;

  const handleWork = useCallback(
    (callback?: WorkCallback) => {
      if (callback) {
        setLoading(true);
        Promise.resolve(callback(onModalClose))
          .catch((e) => onShowError(e.message, true))
          .finally(() => setLoading(false));
      }
    },
    [onShowError, onModalClose]
  );

  const handleConfirm = useCallback(
    () => handleWork(onConfirm),
    [onConfirm, handleWork]
  );

  useEffect(() => {
    isModalOpen && handleWork(onOpen);
  }, [isModalOpen, handleWork, onOpen]);

  return (
    <Fragment>
      {children(onModalOpen)}
      <Dialog
        {...dialogProps}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === "Escape") onModalClose();
        }}
        onDoubleClick={(e) => e.stopPropagation()}
        onProgress={(e) => e.stopPropagation()}
        onKeyPress={(e) => e.stopPropagation()}
        onClose={onModalClose}
        open={isModalOpen}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {typeof content === "function" ? content(onModalClose) : content}
        </DialogContent>
        <DialogActions>
          <div className={classes.actions}>
            {cancel(onModalClose, loading)}
            {confirm && (
              <Fragment>
                <Spacer />
                {confirm(handleConfirm, loading)}
              </Fragment>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default WithDialog;
