import React from "react";
import BorderBox from "src/shared/ui/BorderBox";
import { PlainRouterLink } from "src/components/Links";
import FlatButton from "src/common/FlatButton";
import { numberFormat } from "src/shared/helpers";
import Spacer from "src/shared/ui/Spacer";
import AddIcon from "@material-ui/icons/AddOutlined";
import { makeStyles, Theme } from "@material-ui/core";
import { AppState } from "src/store";
import { isProPlanActive } from "src/shared/types/user";
import { connect } from "react-redux";

interface BillingInfoProps {
  isPro: boolean;
  remainingUnitCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  billingLinkContainer: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  billingButtonText: {
    fontSize: "12px",
  },

  upgradeContainer: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "none",
  },
  upgradeButtonText: {
    display: "inline-block",
    padding: theme.spacing(0, 5),
    textTransform: "uppercase",
  },
}));

const BillingInfo: React.FC<BillingInfoProps> = (props) => {
  const classes = useStyles();
  const { isPro, remainingUnitCount } = props;

  if (isPro || 1 > 0) return null;

  return (
    <>
      <BorderBox right size={2}>
        <span className={classes.billingLinkContainer}>
          <PlainRouterLink to='/account/billing'>
            <FlatButton variant='outlined' size='small' color='primary'>
              <span className={classes.billingButtonText}>
                Units: <strong>{numberFormat(remainingUnitCount)}</strong>
              </span>
              <Spacer />
              <AddIcon color='primary' />
            </FlatButton>
          </PlainRouterLink>
        </span>
      </BorderBox>

      <BorderBox right size={2}>
        <span className={classes.upgradeContainer}>
          <PlainRouterLink to='/account/billing'>
            <FlatButton variant='contained' color='primary' size='small'>
              <span className={classes.upgradeButtonText}>Upgrade To Pro</span>
            </FlatButton>
          </PlainRouterLink>
        </span>
      </BorderBox>
    </>
  );
};

const mapState = (state: AppState) => {
  const { subscription, remainingUnitCount } = state.me!;

  return {
    isPro: isProPlanActive(subscription),
    remainingUnitCount,
  };
};

export default connect(mapState)(React.memo(BillingInfo));
