import useDialog from "src/common/useDialog";
import React from "react";

type Closer = (event: React.KeyboardEvent | React.MouseEvent) => void;

const conditionalCloser =
  (close: Function): Closer =>
  (event) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    close();
  };

export default function useDrawer(open = false): [boolean, () => void, Closer] {
  const [isOpen, onOpen, onClose] = useDialog(open);

  const maybeCloseDrawer = React.useCallback(
    () => conditionalCloser(onClose),
    [onClose]
  );

  return React.useMemo(
    () => [isOpen, onOpen, maybeCloseDrawer],
    [isOpen, onOpen, maybeCloseDrawer]
  );
}
