import Api from "src/shared/Api";
import { FieldArray } from "src/global/fields";
import { WithFormId } from "src/api/utils";

const BASE = "/forms/:formId/fields";

export default class FormFieldsResource {
  static list(params: WithFormId) {
    return Api.get<FieldArray>(Api.replace(BASE, params));
  }
}
