import React from "react";
import { SideBarLink } from "src/components/SideBar";
import {
  AUTO_ALL,
  AUTO_SHARED,
  AUTO_UNCATEGORIZED,
  AUTO_WORKSPACE_NAME,
  selectForWorkspace,
  selectShared,
  selectUncategorized,
} from "src/workspace/utils";
import { AppState } from "src/store";
import { WithID } from "src/global";
import { connect } from "react-redux";
import { selectFormList } from "src/store/forms";
import { selectWorkspaceById, selectWorkspaceIds } from "src/store/workspaces";

interface WorkspaceSideBarLinkProps {
  id: string;
  name: string;
  formCount: number;
}

const WorkspaceSideBarLink: React.FC<WorkspaceSideBarLinkProps> = (props) => {
  const { name, id, formCount } = props;

  return (
    <SideBarLink
      to={id === AUTO_ALL ? "/workspaces" : `/workspaces/${id}`}
      text={name}
      exact={id === AUTO_ALL}
      action={formCount}
    />
  );
};

const mapStateToProps = (state: AppState, props: WithID) => {
  const forms = selectFormList(state);
  const workspaceID = props.id;
  const userID = state.me!.id;

  const getCountAndName = (): [number, string] => {
    if (workspaceID === AUTO_SHARED)
      return [
        selectShared(forms, userID).length,
        AUTO_WORKSPACE_NAME[AUTO_SHARED],
      ];

    if (workspaceID === AUTO_UNCATEGORIZED)
      return [
        selectUncategorized(forms, selectWorkspaceIds(state), userID).length,
        AUTO_WORKSPACE_NAME[AUTO_UNCATEGORIZED],
      ];

    if (workspaceID === AUTO_ALL)
      return [forms.length, AUTO_WORKSPACE_NAME[AUTO_ALL]];

    const workspace = selectWorkspaceById(state, workspaceID);
    return [
      selectForWorkspace(forms, workspaceID).length,
      workspace ? workspace.name : "",
    ];
  };

  const [formCount, name] = getCountAndName();
  return { formCount, name };
};

export default connect(mapStateToProps)(React.memo(WorkspaceSideBarLink));
