import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
  },
});

const FlatButton: React.FC<React.ComponentProps<typeof Button>> = ({ className, ...others }) => {
  const classes = useStyles();
  return <Button {...others} className={clsx(className, classes.root)}/>;
};

export default FlatButton;
