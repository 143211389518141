import ConditionalRoute from "./ConditionalRoute";
import AfterAuthLocation from "src/components/Routing/AfterAuthLocation";

const ProtectedRoute = ConditionalRoute({
  bad: false,
  to: (props) => {
    AfterAuthLocation.set(props.location || "");
    return "/login";
  },
});

export default ProtectedRoute;
