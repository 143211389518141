import { WorkspaceForm } from "src/shared/types/form";
import { StringMap } from "src/global";

export const AUTO_ALL = "all";
export const AUTO_SHARED = "shared";
export const AUTO_UNCATEGORIZED = "uncategorized";

export const AUTO_WORKSPACE_NAME: StringMap<string> = {
  [AUTO_ALL]: "All Forms",
  [AUTO_SHARED]: "Shared with Me",
  [AUTO_UNCATEGORIZED]: "Uncategorized",
};

export const selectShared = (forms: WorkspaceForm[], userID: string) =>
  forms.filter((form) => form.user !== userID);

export const selectUncategorized = (
  forms: WorkspaceForm[],
  workspaces: string[],
  userID: string
) =>
  forms.filter(
    (form) => form.user === userID && !workspaces.includes(form.workspace)
  );

export const selectForWorkspace = (forms: WorkspaceForm[], workspace: string) =>
  forms.filter((form) => form.workspace === workspace);
