import { RedirectProps } from "react-router";
import Db from "src/shared/Db";

type Location = RedirectProps["to"];

const KEY = "__js_aul__";

const AfterAuthLocation = {
  set(location: Location) {
    Db.set(KEY, JSON.stringify(location));
  },

  get(default_ = "/"): Location {
    let location = default_;
    const cookie = Db.get(KEY);
    if (cookie) {
      try {
        location = JSON.parse(cookie);
      } catch (e) {}
    }

    Db.clear(KEY);
    return location;
  },
};

export default AfterAuthLocation;
