import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import SideBarItem, {
  BaseSideBarItemProps,
} from "src/components/SideBar/SideBarItem";

type SideBarButtonProps = BaseSideBarItemProps & ButtonProps;

const SideBarButton: React.FC<SideBarButtonProps> = (props) => {
  return <SideBarItem {...props} component={Button} />;
};

SideBarButton.defaultProps = {
  variant: "text",
  size: "small",
};

export default React.memo(SideBarButton);
