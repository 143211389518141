import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { WorkspaceForm } from "src/shared/types/form";
import { FETCH_STATUS_IDLE, ResourceState } from "src/store/resource";
import { createSortCompareDate } from "src/global";
import { AppState } from "src/store/index";
import FormsResource from "src/api/FormsResource";
import { shouldFetch } from "src/store/utils";
import createAsyncThunkReducers from "src/store/createAsyncThunkReducers";

export const fetchForms = createAsyncThunk(
  "forms/fetch",
  () => FormsResource.list(),
  {
    condition(arg: void, { getState }) {
      const state = getState() as AppState;
      return shouldFetch(selectFormsStatus(state));
    },
  }
);

type State = ResourceState<WorkspaceForm>;

const adapter = createEntityAdapter<WorkspaceForm>({
  sortComparer: createSortCompareDate<WorkspaceForm>(
    (form) => form.createdAt,
    true
  ),
});

const initialState: State = adapter.getInitialState({
  status: FETCH_STATUS_IDLE,
});

const slice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    save: adapter.upsertOne,
    remove: adapter.removeOne,
    patch: adapter.updateOne,
  },
  extraReducers: (builder) => {
    createAsyncThunkReducers<State, WorkspaceForm[]>({
      thunk: fetchForms,
      receive: adapter.setAll,
      setStatus: (state, status) => (state.status = status),
    })(builder);
  },
});

export const FormsReducer = slice.reducer;

// actions
export const patchFormById = slice.actions.patch;
export const removeFormById = slice.actions.remove;
export const addForm = slice.actions.save;

// selectors
const selectState = (state: AppState) => state.forms;
const selectors = adapter.getSelectors(selectState);
export const selectFormById = selectors.selectById;
export const selectFormIds = selectors.selectIds;
export const selectFormList = selectors.selectAll;
export const selectFormEntities = selectors.selectEntities;
export const selectFormCount = selectors.selectTotal;
export const selectFormsStatus = (state: AppState) => selectState(state).status;
