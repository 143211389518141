import { createSelector } from "reselect";
import { Form } from "src/shared/types/form";
import { selectFieldList } from "src/store/form/fields";
import { createAction } from "@reduxjs/toolkit";
import { AppState } from "src/store";

const selectForm = (state: AppState) => state.form.form;
export const selectDenormalizedForm = createSelector(
  [selectForm, (state: AppState) => selectFieldList(state)],
  (form, fields): Form => ({
    ...form,
    fields,
  })
);

export const formCleanup = createAction<void>("form/cleanup");

// Form.name, Form.workspace, Form.theme,
// Delete responses
//  Delete form
