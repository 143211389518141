import Button from "@material-ui/core/Button";
import { PlainFn } from "src/shared/types";

const DefaultCancel = (onClick: PlainFn, loading: boolean) => (
  <Button onClick={onClick} variant='outlined' disabled={loading}>
    Cancel
  </Button>
);

export default DefaultCancel;
