import React, { memo, useCallback } from "react";
import EditIcon from "@material-ui/icons/Edit";
import OpacityIcon from "@material-ui/icons/Opacity";
import SettingsIcon from "@material-ui/icons/Settings";
import ShareIcon from "@material-ui/icons/Share";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/Toc";
import ChartIcon from "@material-ui/icons/BarChart";
import CropIcon from "@material-ui/icons/Crop";
import SidePanelButton from "./SidePanelButton";
import { Box } from "@material-ui/core";
import useCachedCallback from "src/shared/hooks/useCachedCallback";
import useTheme from "@material-ui/core/styles/useTheme";
import BorderBox from "src/shared/ui/BorderBox";
import { ACCESS_LEVEL_ADMIN, hasClearance } from "src/global";
import Scrollable from "src/shared/ui/Scrollable";
import ViewIcon from "@material-ui/icons/RemoveRedEye";
import RenameIcon from "@material-ui/icons/TextFields";
import WithFormRenameDialog from "src/pages/FormPage/withLayout/WithFormRenameDialog";
import Hidden from "@material-ui/core/Hidden";
import { useHistory, useParams } from "react-router-dom";
import {
  SECTION_DESIGN,
  SECTION_RESPONSES,
  SECTION_RESULTS,
  SECTION_SETTINGS,
  SECTION_SHARE,
  SECTION_TEAM,
  SECTION_THEME,
  SECTION_WIDGET,
} from "src/constants";
import useLink from "src/pages/FormPage/SharePanel/useLink";
import { useAppSelector } from "src/store";
import { selectFormId, selectFormState } from "src/store/form/form";

const SidePanel: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { section } = useParams();

  const formId = useAppSelector(selectFormId);
  const accessLevel = useAppSelector(
    (state) => selectFormState(state).accessLevel
  );

  const handleClick = useCallback(
    (section: string) => history.push(`/forms/${formId}/${section}`),
    [formId, history]
  );

  const getClickHandler = useCachedCallback(handleClick);

  const previewLink = useLink();
  const handlePreviewClick = useCallback(
    () => window.open(previewLink, "_blank"),
    [previewLink]
  );

  return (
    <BorderBox right>
      <Box height='100%' bgcolor={theme.palette.background.default}>
        <Scrollable>
          <SidePanelButton
            icon={<EditIcon />}
            label='Design'
            description='Design your questionnaire'
            active={SECTION_DESIGN === section}
            onClick={getClickHandler(SECTION_DESIGN)}
          />

          <SidePanelButton
            icon={<OpacityIcon />}
            label='Theme'
            description='Customize the look and feel'
            active={SECTION_THEME === section}
            onClick={getClickHandler(SECTION_THEME)}
          />

          <SidePanelButton
            icon={<SettingsIcon />}
            label='Settings'
            description='Email notifications settings'
            active={SECTION_SETTINGS === section}
            onClick={getClickHandler(SECTION_SETTINGS)}
          />

          <SidePanelButton
            icon={<ShareIcon />}
            label='Share'
            description='Share your questionnaire'
            active={SECTION_SHARE === section}
            onClick={getClickHandler(SECTION_SHARE)}
          />

          {hasClearance(ACCESS_LEVEL_ADMIN, accessLevel) && (
            <SidePanelButton
              icon={<PeopleIcon />}
              label='Team'
              description='Delegate access to my form'
              active={SECTION_TEAM === section}
              onClick={getClickHandler(SECTION_TEAM)}
            />
          )}

          {hasClearance(ACCESS_LEVEL_ADMIN, accessLevel) && (
            <>
              <SidePanelButton
                icon={<ListIcon />}
                label='Responses'
                description='View collected responses'
                active={SECTION_RESPONSES === section}
                onClick={getClickHandler(SECTION_RESPONSES)}
              />

              <SidePanelButton
                icon={<ChartIcon />}
                label='Results'
                description='Responses summary'
                active={SECTION_RESULTS === section}
                onClick={getClickHandler(SECTION_RESULTS)}
              />

              <SidePanelButton
                icon={<CropIcon />}
                label='Chart'
                description='Main Data Visualization'
                active={SECTION_WIDGET === section}
                onClick={getClickHandler(SECTION_WIDGET)}
              />
            </>
          )}

          <Hidden smUp>
            <SidePanelButton
              icon={<ViewIcon />}
              label='Preview'
              description='Form preview'
              onClick={handlePreviewClick}
            />

            <WithFormRenameDialog>
              {(onClick) => (
                <SidePanelButton
                  icon={<RenameIcon />}
                  label='Rename'
                  description='Rename this form'
                  onClick={onClick}
                />
              )}
            </WithFormRenameDialog>
          </Hidden>
        </Scrollable>
      </Box>
    </BorderBox>
  );
};

export default memo(SidePanel);
