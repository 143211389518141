import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FETCH_STATUS_IDLE, ResourceState } from "src/store/resource";
import { createSortCompareDate, WithID } from "src/global";
import { Dashboard, WidgetLayout } from "src/store/dashboards/types";
import { AppState } from "src/store";
import DashboardsResource from "src/dashboard/api/DashboardsResource";
import createAsyncThunkReducers from "src/store/createAsyncThunkReducers";
import { shouldFetch } from "src/store/utils";
import { Draft } from "immer";

export const fetchDashboards = createAsyncThunk(
  "dashboards/fetch",
  () => DashboardsResource.list(),
  {
    condition(arg: void, { getState }) {
      const state = getState() as AppState;
      return shouldFetch(selectDashboardsStatus(state));
    },
  }
);

type State = ResourceState<Dashboard>;

const adapter = createEntityAdapter<Dashboard>({
  sortComparer: createSortCompareDate<Dashboard>((x) => x.createdAt, true),
});

const initialState: State = adapter.getInitialState({
  status: FETCH_STATUS_IDLE,
});

type LayoutPayloadAction<P> = PayloadAction<WithID & { payload: P }>;

const makeLayoutReducer =
  <P>(reducer: (layouts: WidgetLayout[], payload: P) => WidgetLayout[]) =>
  (state: Draft<State>, action: LayoutPayloadAction<P>) => {
    const { id, payload } = action.payload;
    const dashboard = state.entities[id];
    if (dashboard) dashboard.layouts = reducer(dashboard.layouts, payload);
  };

const slice = createSlice({
  name: "dashboards",
  initialState,
  reducers: {
    save: adapter.upsertOne,
    remove: adapter.removeOne,
    patch: adapter.updateOne,

    removeLayout: makeLayoutReducer((layouts, layoutId: string) =>
      layouts.filter((x) => x.id !== layoutId)
    ),

    appendLayout: makeLayoutReducer((layouts, layout: WidgetLayout) => [
      ...layouts,
      layout,
    ]),

    saveLayouts: makeLayoutReducer((_, layouts: WidgetLayout[]) => [
      ...layouts,
    ]),
  },
  extraReducers: (builder) => {
    createAsyncThunkReducers<State, Dashboard[]>({
      thunk: fetchDashboards,
      receive: adapter.addMany,
      setStatus: (state, status) => (state.status = status),
    })(builder);
  },
});

export const DashboardReducer = slice.reducer;

export const DashboardActions = slice.actions;

export const patchDashboardById = slice.actions.patch;
export const removeDashboardById = slice.actions.remove;
export const saveDashboard = slice.actions.save;
export const appendToDashboardLayouts = slice.actions.appendLayout;
export const saveDashboardLayouts = slice.actions.saveLayouts;
export const removeDashboardLayoutById = slice.actions.removeLayout;

const selectState = (state: AppState) => state.dashboards;
const selectors = adapter.getSelectors(selectState);
export const selectDashboardById = selectors.selectById;
export const selectDashboardIds = selectors.selectIds;
export const selectDashboardList = selectors.selectAll;
export const selectDashboardEntities = selectors.selectEntities;
export const selectDashboardsStatus = (state: AppState) =>
  selectState(state).status;

export const DashboardSelectors = selectors;
