import React from "react";
import ProtectedRoute from "src/components/Routing/ProtectedRoute";
import Layout from "src/components/Layout/Layout";
import { useAppSelector } from "src/store";

interface ProtectedWithLayoutRouteProps
  extends React.ComponentProps<typeof ProtectedRoute> {
  drawer?: React.ComponentType;
}

const ProtectedWithLayoutRoute: React.FC<ProtectedWithLayoutRouteProps> = (
  props
) => {
  const { drawer, ...otherProps } = props;
  const isUserLoggedIn = useAppSelector((state) => !!state.me);

  if (!isUserLoggedIn) return <ProtectedRoute {...otherProps} />;

  return (
    <Layout drawer={drawer}>
      <ProtectedRoute {...otherProps} />
    </Layout>
  );
};

export default ProtectedWithLayoutRoute;
