import { useCallback } from "react";
import { LightForm } from "src/shared/types/form";
import { patchForm } from "src/store/form/form";
import { useAppDispatch } from "src/store";

const useFormPathUpdate = <Key extends keyof LightForm>(key: Key) => {
  const dispatch = useAppDispatch();
  return useCallback(
    (value: LightForm[Key]) => {
      dispatch(patchForm({ [key]: value }));
    },
    [key, dispatch]
  );
};

export default useFormPathUpdate;
